define("itarp-business-application/controllers/profile/autofill", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _controller, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProfileAutofillController = (_dec = (0, _service.inject)('ad-token-service'), _dec2 = (0, _service.inject)('store'), (_class = class ProfileAutofillController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "adTokenService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "errors", _descriptor3, this);

      _initializerDefineProperty(this, "fieldError", _descriptor4, this);

      _initializerDefineProperty(this, "uploadError", _descriptor5, this);
    }

    saveChanges(transition) {
      const success = response => {
        if (transition) {
          this.transitionToRoute('profile.basic-info');
        }
      };

      const failure = response => {
        this.errors = response.errors;
        this.uploadError = true;
      };

      this.model.page = {
        slug: 'autofill'
      };
      this.model.save().then(success, failure);
    }

    async continue() {
      if (this.model.profiles.get('status') === 'declined') {
        if (this.model.profiles.hasDirtyAttributes) {
          this.model.profiles.set('status', 'pending_approval');
        }
      }

      this.saveChanges(true);
    }

    get uploadConfig() {
      return {
        attributes: this.uploadAttributes,
        observer: {
          subscribe: () => {}
        }
      };
    }

    get uploadAttributes() {
      return {
        label: 'Resume',
        type: 'upload-resume',
        required: true,
        hidden: false,
        alignment: 'boxes',
        resume: null,
        field: 'resumes',
        hasError: this.uploadError,
        mimetypes: ['application/pdf', 'application/msword'],
        multiple: false,
        value: this.model.profiles.get('resume'),
        setUploadUrl: val => {
          this.model.profile.set('resume', `/api/expert/storage/download/${val}`);
          this.saveChanges(false);
        }
      };
    }

    get uploadConfig() {
      return {
        attributes: this.uploadAttributes,
        observer: {
          subscribe: () => {}
        }
      };
    }

    get value() {
      return this.model.profile.get('resume');
    }

    set value(val) {
      this.model.profile.set('resume', val);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fieldError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uploadError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "continue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "continue"), _class.prototype)), _class));
  _exports.default = ProfileAutofillController;
});