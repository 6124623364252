define("itarp-admin-dashboard/controllers/application", ["exports", "@ember/controller", "@ember/service", "@glimmer/tracking", "@ember/object"], function (_exports, _controller, _service, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationController = (_dec = (0, _service.inject)('ad-token-service'), (_class = class ApplicationController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "layout", _descriptor, this);

      _initializerDefineProperty(this, "navigation", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "toast", _descriptor4, this);

      _initializerDefineProperty(this, "notify", _descriptor5, this);

      _initializerDefineProperty(this, "isAccountNoticeVisible", _descriptor6, this);

      _initializerDefineProperty(this, "auth", _descriptor7, this);
    }

    get isApprovedVisible() {
      // additional check for approved state to make sure user has not previously closed it
      return this.isAccountNoticeVisible && this.auth.tokenInformation.extension_user_type === 'expert' && this.auth.tokenInformation.extension_status === 'approved' && !this.auth.isAccountNoticeClosed();
    }

    get isPendingApprovalVisible() {
      return this.isAccountNoticeVisible && this.auth.tokenInformation.extension_user_type === 'expert' && this.auth.tokenInformation.extension_status === 'pending_approval';
    }

    get isDeclineVisible() {
      return this.isAccountNoticeVisible && this.auth.tokenInformation.extension_user_type === 'expert' && this.auth.tokenInformation.extension_status === 'declined';
    }

    registerToastComponent(component) {
      this.notify = component;
      this.notifications.addNotificationObserver(this.alertNewNotification);
    }

    alertNewNotification() {
      this.toast.show('new notification');
    }

    closeAccountNotice() {
      this.isAccountNoticeVisible = false; // for approved status we will not show notice banner if the user has closed it once

      if (this.auth.tokenInformation.extension_status === 'approved') {
        this.auth.setAccountNoticeClosed();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "layout", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "navigation", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notify", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isAccountNoticeVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "registerToastComponent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerToastComponent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "alertNewNotification", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "alertNewNotification"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeAccountNotice", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeAccountNotice"), _class.prototype)), _class));
  _exports.default = ApplicationController;
});